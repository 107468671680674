import React, { useRef, useState } from 'react'
import './App.css'
import { ReactComponent as Spinner } from './assets/spinner.svg'

function App() {
  const ref = useRef()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [hoveringButton, setHoveringButton] = useState(false)

  const onClick = async () => {
    if (!ref.current.reportValidity()) {
      return
    }

    try {
      setLoading(true)
      await window.fetch(
        'https://us-central1-nichemates-75f78.cloudfunctions.net/addToList',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            listId: '8e8a02a3-c34a-4180-bff6-cf51c5dbcfd3',
            email: ref.current.value,
          }),
        },
      )

      if (window.gtag) {
        window.gtag('event', 'generate_lead')
      }
      setSuccess(true)
    } catch (err) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onFocus = () => {
    setSuccess(false)
    setError(false)
  }

  return (
    <div className="flex items-center justify-center h-100">
      <div className="w-90 w-50-l">
        <h1 className="headline f1">
          Oi
          <span role="img" aria-label="wave">
            {' '}
            👋
          </span>
        </h1>
        <p className="f4">
          We’re just a couple mates trying to solve niche problems that us or
          our friends have and we launch
          <span className="b">
            &nbsp;some stuff, sometimes, all the time
            <span role="img" aria-label="wave">
              {' '}
              🤙
            </span>
          </span>
        </p>
        <div className="flex flex-column">
          <p className="f5 silver">
            Follow our Journey
            <span role="img" aria-label="wave">
              {' '}
              👇
            </span>
          </p>
          <div className="flex flex-wrap">
            <input
              required
              ref={ref}
              onFocus={onFocus}
              className="w-100 w-50-l input pa3 mr0 mr1-l mb1 mb0-l br3"
              placeholder="E-Mail"
              type="email"
              autoComplete="email"
            />
            <button
              type="button"
              onMouseEnter={() => setHoveringButton(true)}
              onMouseLeave={() => setHoveringButton(false)}
              disabled={loading}
              className="w-100 w-30-l button pa3 br3"
              onClick={onClick}>
              {loading ? (
                <Spinner
                  width={14}
                  height={14}
                  className={`${hoveringButton ? 'black' : 'white'}`}
                />
              ) : (
                'Subscribe'
              )}
            </button>
          </div>
          <p
            className={`f5 mb-0 ${error ? 'red' : 'green'} b ${
              error || success ? 'o-100' : 'o-0'
            }`}>
            {!error && (
              <>
                <span>Cheers Mate.&nbsp;</span>
                <span role="img" aria-label="wave">
                  🎉
                </span>
              </>
            )}
            {error && (
              <>
                <span>Something went wrong.&nbsp;</span>
                <span role="img" aria-label="wave">
                  😵
                </span>
              </>
            )}
          </p>
          <p className="f6 silver">
            Got a problem we share?
            <br />
            Say g&apos;day&nbsp;
            <a href="mailto:oi@nichemat.es" className="underline">
              oi@nichemat.es
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default App
